<script>
  import { onMount } from "svelte";
  import _ from "lodash";
  import dayjs from "dayjs";
  import AdvancedFormat from "dayjs/plugin/advancedFormat"; // load on demand
  dayjs.extend(AdvancedFormat); // use plugin
  const address = "";
  let loaded = false;
  let currentCompany = true;
  let partners = [];
  let homepage = {};
  let calendars = [];
  let badges = [];
  let year = new Date().getFullYear();

  onMount(async function() {
    await refreshData();
    await getCalendarDates();

    setInterval(async function() {
      await getCalendarDates();
    }, 20000);

    setInterval(async function() {
      await refreshData();
      year = new Date().getFullYear();
    }, 300000);

    async function getCalendarDates() {
      const calendarsResponse = await fetch(address + "/calendars");
      const calendarsArray = await calendarsResponse.json();

      calendars = _.filter(calendarsArray, function(o) {
        return (
          dayjs(o.Start).isBefore(dayjs()) &&
          dayjs(o.End).isAfter(dayjs()) &&
          !o.Hide
        );
      });

      currentCompany = calendars.length > 0 ? true : false;
    }

    async function refreshData() {
      const badgesResponse = await fetch(address + "/badges");
      badges = await badgesResponse.json();
   
      const partnersResponse = await fetch(address + "/partners");
      partners = await partnersResponse.json();

      const homepageResponse = await fetch(address + "/homepages");
      homepage = ((await homepageResponse.json()) || [])[0];
    }

    loaded = true;
  });
</script>

<style>
  #logo {
    margin: 23px auto;
    display: block;
    width: 13vw;
    max-width: 80%;
    position: relative;
  }

  .welcome-text {
    position: absolute;
    font-family: "MuseoSlab-300", sans-serif;
    text-align: center;
    color: white;
    font-size: 6vw;
    left: 50%;
    line-height: 0.9em;
    top: 49%;
    transform: translate(-50%, -50%);
  }

  .welcome-text-bold {
    font-family: "MuseoSlab-900", sans-serif;
    font-size: 1.57em;
  }

  .current-company .welcome-text {
    font-size: 5vw;
    font-family: "MuseoSlab-900", sans-serif;
    top: 32%;
  }

  .current-company .welcome-text .welcome-text-bold {
    font-size: 1.05em;
    font-family: "MuseoSlab-300", sans-serif;
  }

  .company-details {
    position: absolute;
    left: 50%;
    top: 54%;
    transform: translateX(-50%);
    display: flex;
  }

  .company-wrapper {
    text-align: center;
    background: white;
    font-size: 20px;
    border-radius: 8px;
    line-height: 2.9em;
    margin: 0 15px;
    min-width: 17vw;
    max-width: 21vw;
    font-size: 15px;
    color: #3e3e3e;
    opacity: 0.95;
    position: relative;
    padding: 2.5vw 0.5vw;
    border-top: 60px solid #f9f9f9;
    padding: 0 0.5vw 2.5vh;
    min-height: 15vh;
  }

  .company-grey-border {
    background: #d6d6d6;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .company-names {
    font-size: 20px;
    line-height: 100%;
    padding: 15px 0 8px;
  }

  .company-title {
    font-size: 22px;
    line-height: 125%;
    font-family: "Museo-700", sans-serif;
  }

  .company-image {
    height: 40px;
    border: 1px solid #d6d6d6;
    border-radius: 60px;
    position: relative;
    top: -30px;
    background: white;
    z-index: 18;
    padding: 10px 23px;
    display: inline-flex;
    align-items: center;
    max-width: 65%;
    justify-content: center;
  }

  .company-logo {
    max-height: 100%;
    max-width: 100%;
  }

  .company-description {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    top: calc(-2.5vh - 30px);
    height: calc(100% - 2.5vh);
    padding-top: calc(20px);
  }

  .badges {
    position: absolute;
    right: 20px;
    bottom: 12px;
    text-align: right;
    color: white;
    font-size: 12px;
  }

  .badge {
    margin-left: 1.2vw;
    margin-bottom: 6px;
    max-height: 5vh;
    max-width: 4.9vw;
    vertical-align: middle;
  }

  .partners {
    position: absolute;
    left: 10px;
    bottom: 21px;
    padding: 10px 15px;
    border-radius: 7px;
    height: 4.5vh;
  }

  .partner {
    margin: 0px 13px;
    max-height: 100%;
    max-width: 6vw;
    vertical-align: middle;
  }
  .background-image-wrapper {
    background-size: cover;
    position: absolute;
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
  }

  .welcome-video {
    position: fixed;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .wrapper {
    margin: 8px;
  }

  .background-filter {
    background-color: rgba(115, 63, 11, 0.5);
    background-blend-mode: multiply;
    height: 100%;
    width: 100%;
    z-index: 0;
    position: absolute;
  }
</style>

{#if loaded}
<div class="background-image-wrapper" style="background-image: url({(homepage.background || {}).url});">
  {#if homepage.Optional_Video_URL}
    <video class="welcome-video" src="{homepage.Optional_Video_URL}" autoplay="autoplay" loop="true" muted="true"/>
  {/if}
  <div class="background-filter" style="background-color: {homepage.filter}"></div>
  <div class="wrapper" class:current-company={currentCompany}>
    <div class="badges" style="opacity: {homepage.Opacity_Enabled ? 0.7 : 1}">
      {#each badges as { Logos, Description }, i}
        {#each Logos as { url }, i}
          <img src="{address}{url}" alt="badge-logo" class="badge" />
        {/each}
        <br />
        {Description.replace('{year}', year)}
      {/each}
    </div>
    <div class="partners" style="opacity: {homepage.Opacity_Enabled ? 0.7 : 1}">
      {#each partners as { Name, Logo, Hide }, i}
        {#if !Hide}
          <img src="{address}{Logo.url}" alt={Name} class="partner" />
        {/if}
      {/each}
    </div>

    <img src="{(homepage.logo || {}).url}" alt="Logo" id="logo" />

    {#if !currentCompany}
      {#if !(homepage.welcome_override || {}).url}
        <div class="welcome-text">
          <span class="welcome-text-bold">WELCOME</span>
          <br />
          TO GOLFBREAKS
        </div>
      {:else}
       <div class="welcome-text">
          <img src="{(homepage.welcome_override || {}).url}" alt="welcome override" class="welcome-override" />
        </div>
      {/if}
    {:else}
      <div class="welcome-text">
        <span class="welcome-text-bold">TODAY WE</span>
        <br />
        WELCOME
      </div>
    {/if}
    <div class="company-details">
      {#each calendars as { Company, Logo, Name }, i}
        <div class="company-wrapper">
          <div class="company-grey-border" />
          <div class="company-image">

            {#if Logo && Logo.url}
              <img
                src="{address}{Logo.url}"
                alt="company-logo"
                class="company-logo" />
            {:else}
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="building"
                class="svg-inline--fa fa-building fa-w-14"
                role="img"
                height="18"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512">
                <path
                  fill="#828282"
                  d="M128 148v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0
                  6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm140 12h40c6.6 0
                  12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12
                  12v40c0 6.6 5.4 12 12 12zm-128 96h40c6.6 0 12-5.4
                  12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6
                  5.4 12 12 12zm128 0h40c6.6 0 12-5.4
                  12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6
                  5.4 12 12 12zm-76 84v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12
                  5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm76
                  12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12
                  5.4-12 12v40c0 6.6 5.4 12 12 12zm180 124v36H0v-36c0-6.6 5.4-12
                  12-12h19.5V24c0-13.3 10.7-24 24-24h337c13.3 0 24 10.7 24
                  24v440H436c6.6 0 12 5.4 12 12zM79.5 463H192v-67c0-6.6 5.4-12
                  12-12h40c6.6 0 12 5.4 12 12v67h112.5V49L80 48l-.5 415z" />
              </svg>
            {/if}
          </div>
          <div class="company-description">
            {#if Company}
              <div class="company-title">{Company}</div>
            {/if}
            {#if Name}
              <div class="company-names">{Name}</div>
            {/if}
          </div>
        </div>
      {/each}
    </div>
  </div>
  </div>
{/if}
